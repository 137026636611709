<template>
  <article v-loading="loading">
    <RouterNav :name="name"></RouterNav>
    <div class="work-detail">
      <div class="work-detail-header">
        <div class="work-info">
          <div class="info-work-name mb-10">{{ workStudentReportInfo.workName }}</div>
          <div class="info-detail">
            <div class="info-desc active-rate mr-28">完成度：{{ workStudentReportInfo.activeRate }}%</div>
            <div class="info-desc update-at">完成时间：{{ workStudentReportInfo.finishTime ? common.formatDate('MM/dd hh:mm', new Date(workStudentReportInfo.finishTime)) : '-' }}</div>
          </div>
        </div>
        <div class="submit-btn" @click="review">点评学生</div>
      </div>
      <div class="content-detail">
        <div class="item-title">学生成绩</div>
        <div class="student-record">
          <div class="all-accuracy record-info">
            <Gauge ref="allAccuracy"></Gauge>
            <div class="info-content">
              <div class="info-text">
                <span class="info-text-title">总成绩：</span>
                <span class="score-color" :class="scoreRate >= 85 ? 'green' : (85 > scoreRate && scoreRate >= 60 ? 'yellow' : '')">{{ (parseInt(score *100)/100).toFixed(1) }}/{{ totalScore }}分</span>
              </div>
              <div class="info-text">
                <span class="info-text-title">班级排名：</span>
                <span class="blue">第{{ rank }}名</span>
              </div>
            </div>
          </div>
          <div class="record-info">
            <Gauge ref="eachQuestion" class="gauge"></Gauge>
            <div class="info-content" >
              <ScrollSelect :questions="eachQuestionRecordList" @changeQuestion="changeQuestion"/>
            </div>
          </div>
        </div>
        <div class="item-title">同比班级平均得分</div>
        <div class="average-score">
          <div ref="echarts" class="average-score-echarts"></div>
        </div>
        <div v-if="flag != 4 && reviewsList && reviewsList.length" class="item-title">智能点评</div>
        <div v-if="flag != 4 && reviewsList && reviewsList.length" class="reviews-list">
          <div v-for="(reviews, index) in reviewsList" :key="index" class="reviews-item">
            <template v-if="flag === 8">
              <div>
                <span>{{ reviews.typeName }}</span>
                <span :class="[reviews.answerRank === '优秀' ? 'col-1DC060' : reviews.answerRank === '良好' ? 'col-fed942' : 'col-FF3C30']">{{ reviews.answerRank }}</span>
              </div>
              <div>{{ reviews.aiAnalysisContent }}</div>
            </template>
            <template v-else>
              <div>
                <span>{{ reviews.title }}</span>
                <span :class="[reviews.exStatus === 1 ? 'col-1DC060' : reviews.exStatus === 2 ? 'col-fed942' : 'col-FF3C30']">{{ reviews.expression }}</span>
              </div>
              <div>{{ reviews.showText }}</div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      :dialogType="1"
      :name="dialogName"
      :id="dialogId"
      :list="dialogList"
      :visible="dialogVisible"
      @handleClose="handleClose"
      @addReview="addReview"
    ></Dialog>
  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav.vue'
import { studentReport } from '@/api/official'
import { newPersonalReviews } from '@/api/common'
import Gauge from '@/views/report/components/Gauge'
import ScrollSelect from '@/components/common/scrollSelect'
import echarts from "echarts";
import Dialog from '@/views/homework/check/components/Dialog'
import common from "@/utils/utils";

const ress = {}
export default {
  name: 'WorkStudentReport',
  components: {
    RouterNav,
    Dialog,
    Gauge,
    ScrollSelect
  },
  data() {
    return {
      loading: false,
      name: '',
      userId: '',
      itemId: '',
      flag: '',
      reviewsList: [],
      classExamInfo: {},
      workStudentReportInfo: {},
      percentage: 10,
      colors: [
        {color: '#f56c6c', percentage: 20},
        {color: '#e6a23c', percentage: 40},
        {color: '#5cb87a', percentage: 60},
        {color: '#1989fa', percentage: 80},
        {color: '#6f7ad3', percentage: 100}
      ],
      rank: '',
      score: '',
      scoreRate: '',
      totalScore: '',
      persional: {},
      studentScores: [],
      classScores: [],
      eachQuestionRecordList: [],
      eachQuestionRecordListForCharts: [],
      dialogId: '',
      dialogName: '',
      dialogVisible: false,
      dialogList: []
    }
  },
  created() {
    this.userId = this.$route.query.userId
    this.itemId = this.$route.query.itemId
    this.flag = this.$route.query.flag
    this.workStudentReportInfo = JSON.parse(sessionStorage.getItem('workStudentReport'))
    this.name = this.workStudentReportInfo.realName + '的作业报告'
    this.getStudentReport()
    this.getPersionalReviews()
  },
  methods: {
    // 获取学生个人报告
    getStudentReport() {
      this.loading = true
      const data = {
        userId: this.userId,
        itemId: this.itemId
      }
      studentReport(data).then(res => {
        this.loading = false
        if (res.state === '11') {
          // this.persional = res.data.persional
          this.studentScores = res.data.studentScores.typeScores
          this.classScores = res.data.classScores.typeScores
          this.rank = res.data.rank
          this.scoreRate = res.data.studentScores.scoreRate
          this.score = res.data.studentScores.score || 0
          this.totalScore = res.data.totalScore

          this.eachQuestionRecordList = this.studentScores.filter(question => {
            return question.questionsTypeName !== '总成绩'
          }).map(question => {
            // TODO 缺少个人得分
            return {
              questionAccuracy: question.scoreRate || 0,
              questionScore: question.score || 0,
              questionName: question.questionsTypeName
            }
          })

          // 限制显示内容长度
          this.eachQuestionRecordListForCharts = this.eachQuestionRecordList.map(question => {
            return {
              questionAccuracy: question.questionAccuracy,
              questionScore: question.questionScore,
              questionName:  common.textEllipsis(question.questionName, 8,  '...')
            }
          })

          this.initGaugeCharts(this.$refs.allAccuracy, { questionName: '得分率', questionAccuracy: res.data.studentScores.scoreRate })
          this.initGaugeCharts(this.$refs.eachQuestion, this.eachQuestionRecordListForCharts[0])
          this.setChartsData()
        }
      }).catch(() => {
        this.loading = false
      })
    },
    getPersionalReviews() {
      this.reviewsList = []
      if (this.flag === 8) {
        const params = {
          homeworkFlag: this.flag,
          homeworkItemId: this.itemId,
          userId: this.userId
        }
        newPersonalReviews(params).then(res => {
          const { state, data } = res
          if (state !== '11') return
          this.reviewsList = data

        })
      } else {
        this.$axios({
          method: 'post',
          url: this.$urls.personalReviews,
          data: {
            type: this.flag,
            homeworkId: this.itemId,
            userId: this.userId
          }
        }).then((response) => {
          if (response.state === '11') {
            this.reviewsList = response.data
          }
        }).catch(() => {})
      }
    },
    // 设置图表数据
    setChartsData() {
      let data = []
      let series = []
      if (Array.isArray(this.studentScores) && this.studentScores.length > 0) {
        const obj = {
          name: this.workStudentReportInfo.realName,
          type: 'bar',
          data: [],
          barGap: 0,
          barWidth: '15px',
          tooltip: {
            trigger: 'item',
            confine: true,
            triggerOn: 'click',
            formatter (params) {
              return `${params.seriesName}<br />${params.marker}${params.name} ${params.value}%`
            }
          },
        }
        this.studentScores.forEach(score => {
          data.push(score.questionsTypeName)
          obj.data.push({
            name: score.questionsTypeName,
            value: score.scoreRate,
            monicker: this.workStudentReportInfo.realName
          })
        })
        series.push(obj)
      }
      if (Array.isArray(this.classScores) && this.classScores.length > 0) {
        const obj = {
          name: this.workStudentReportInfo.className,
          type: 'bar',
          data: [],
          barGap: 0,
          barWidth: '15px',
          tooltip: {
            trigger: 'item',
            confine: true,
            triggerOn: 'click',
            formatter (params) {
              return `${params.seriesName}<br />${params.marker}${params.name} ${params.value}%`
            }
          },
        }
        this.classScores.forEach(score => {
          obj.data.push({
            name: score.questionsTypeName,
            value: score.scoreRate,
            monicker: this.workStudentReportInfo.realName
          })
        })
        series.push(obj)
      }
      this.initCharts(series, data)
    },
    // 初始化图表
    initCharts (series, data) {
      const echarts = require('echarts')
      this.myChart = echarts.init(this.$refs.echarts)
      const { rangeArr } = this
      const that = this
      this.myChart.setOption({
        color: ['#309AF2','#E5E5E5'],
        tooltip: {
          trigger: 'item',
          confine: true,
        },
        legend: {
          data: [this.workStudentReportInfo.realName, this.workStudentReportInfo.className],
          right: 28
        },
        grid: {
          left: '1%',
          right: 0,
          bottom: 10,
          containLabel: true
        },
        xAxis: {
          // name: '得分率',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#666'
            },
            emphasis: {
              color: '#fff'
            }
          },
          data
        },
        yAxis: {
          name: '得分率（%）',
          max: 100,
          min: 0
        },
        series
      }, true)
    },
    // 设置仪表盘
    initGaugeCharts (target, data) {
      console.log('initGaugeCharts', data)
      const option = {
        series: [{
          type: 'gauge',
          splitNumber: 1,
          progress: {
            show: true,
            width: 14,
            itemStyle: {
              color: '#3875FD'
            },
            roundCap: true
          },
          axisLine: {
            lineStyle: {
              width: 14,
              color: [
                [1, '#F6F6F6']
              ]
            },
            roundCap: true
          },
          axisTick: {
            show: false
          },
          pointer: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            distance: -35,
            fontSize: 10,
            color: '#1B6BD4'
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            fontSize: 17,
            offsetCenter: [0, 0],
            formatter: function (value) {
              return value + '%\n{unit|' + data.questionName + '}'
            },
            rich: {
              unit: {
                fontSize: 12,
                color: '#999'
              }
            },
            color: '#333333'
          },
          data: [{
            value: data.questionAccuracy
          }]
        }]
      }
      target.initCharts({
        dataParam: option
      })
    },
    changeQuestion(data) {
      this.initGaugeCharts(this.$refs.eachQuestion, data)
    },
    // 点评学生
    review(index) {
      const {list} = this
      // const name = list[index].realName || list[index].name
      this.dialogName = this.workStudentReportInfo.realName
      this.dialogId = this.userId
      // const itemId = this.itemId || list[index].itemId
      this.reviewList(this.userId, this.itemId)
    },
    reviewList(userId, itemId) {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.reviewList,
        data: {
          itemId,
          userId,
          pageIndex: 1,
          pageSize: 10
        }
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.dialogList = response.data
          this.dialogItemId = itemId
          this.dialogVisible = true
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // dialogVisible 切换
    handleClose() {
      this.dialogVisible = false
    },

    addReview(id, content) {
      this.loading = true
      const itemId = this.itemId || this.dialogItemId
      this.$axios({
        method: 'post',
        url: this.$urls.addReview,
        data: {
          itemId,
          userId: id,
          content
        }
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.reviewList(id, itemId)
        }
      }).catch(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.work-detail {
  padding-left: 20px;
  .work-detail-header {
    display: flex;
    //flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 20px 28px;
    margin-bottom: 10px;
    .work-info{
      .info-work-name {
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }
      .info-detail{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .info-desc{
          color: #999999;
          font-size: 15px;
          line-height: 21px;
        }
      }
    }
    .submit-btn {
      background: linear-gradient(to right, #6DBBFC, #309AF2);
      width: 100px;
      height: 36px;
      line-height: 36px;
      border-radius: 4px;
      @include font(15px, #fff, center);
      cursor: pointer;

      &:hover {
        background: #309AF2;
      }

      &:active {
        background: #6DBBFC;
      }
    }


    //.info-desc {
    //  font-size: 15px;
    //  line-height: 21px;
    //  margin-top: 10px;
    //  &.info-info {
    //    color: #333333;
    //  }
    //  &.date-start, &.paper-group {
    //    color: #999999;
    //  }
    //}
  }
  .content-detail{
    .item-title{
      background-color: #FFFFFF;
      height: 66px;
      line-height: 24px;
      position: relative;
      padding: 20px 0 20px 28px;
      font-size: 17px;
      color: #333333;
      //border-left: 1px solid #309AF2;
      &:after{
        content: '';
        height: 20px;
        width: 4px;
        background-color: #309AF2;
        position: absolute;
        left: 0;
        top: 22px;
      }
    }
    .student-record{
      background-color: #FFFFFF;
      margin-bottom: 10px;
      padding: 0 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .record-info{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        height: 220px;
        .gauge{
          width: 220px;
        }
        .info-content{
          text-align: left;
          width: 180px;
          flex: 1;
          .info-text{
            color: #333333;
            line-height: 24px;
            font-size: 17px;
            .info-text-title{
              font-weight: bold;
            }
            .score-color{
              color: #FF3C30;
              &.green{
                color: #1DC060;
              }
              &.yellow{
                color: #FFA516;
              }
            }
            .blue{
              color: #309af2;
            }
          }
        }
      }
      .all-accuracy{
        //display: flex;
        //justify-content: space-between;
        //align-items: center;
        .info-content{
          width: 180px;
        }
      }
    }
    .average-score{
      width: 1080px;
      height: 300px;
      padding: 0 28px 10px;
      margin-bottom: 10px;
      background-color: #fff;
      .average-score-echarts{
        width: 1024px;
        height: 300px;
        //border: 1px solid #e5e5e5;
        border-top: 0;
      }
    }
    .reviews-list{
      width: 100%;
      background-color: #fff;
      padding: 0 28px 14px;
      .reviews-item{
        width: 100%;
        padding: 14px;
        border-radius: 8px;
        word-wrap: break-word;
        background-color: #F6F6F6;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
      }
    }
  }
}
.mr-28{
  margin-right: 28px;
}
.mb-10{
  margin-bottom: 10px;
}
</style>
